import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
} from '../styles';
import { Button, Container, Svg } from './ui';
import Breadcrumbs from './Breadcrumbs';
import careersFlag from '../images/careers-flag.inline.svg';

const StyledCareerSingle = styled.section`
  position: relative;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: relative;
`;

const StyledCareersFlag = styled(Svg)`
  display: none;

  ${minBreakpointQuery.large`
    display: block;
    position: absolute;
    right: 0;
    width: 500px;
    top: 0;
  `}
`;

const StyledInner = styled.div`
  max-width: 1152px;
  margin: auto;
`;

const StyledIntro = styled.div`
  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;

  ${maxBreakpointQuery.small`
    flex-direction: column;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 100px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 120px;
    max-width: 580px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 140px;
    max-width: 720px;
  `}
`;

const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${brandColours.tertiary};
  height: 266px;
  border: solid 1px ${brandColours.quinary};
  flex-shrink: 0;

  ${minBreakpointQuery.small`
    width: 266px;
  `}
`;

const StyledImage = styled.img`
  max-height: 140px;
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 36px;

  ${minBreakpointQuery.small`
    margin-left: 36px;
    margin-top: auto;
    margin-bottom: auto;
  `}
`;

const StyledHeading = styled.h2`
  font-family: ${brandFonts.tertiary};
  ${fontSize(24)};

  ${minBreakpointQuery.small`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(28)};
  `}
`;

const StyledLocation = styled.span`
  ${fontSize(18)}
  margin-top: 10px;
  b {
    font-family: ${brandFonts.tertiary};
    margin-right: 6px;
  }
`;

const StyledSalary = styled.p`
  ${fontSize(18)}
  b {
    font-family: ${brandFonts.tertiary};
    margin-right: 6px;
  }
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.small`
    margin: 0 60px;
  `}
`;

const StyledSubHeading = styled.h2`
  position: relative;
  font-family: ${brandFonts.tertiary};
  ${fontSize(20)};
  margin: 10px 0;

  &:before {
    content: '';
    display: block;
    background-color: ${brandColours.primary};
    width: 22px;
    height: 2px;
    position: absolute;
    left: -30px;
    bottom: 9px;

    ${minBreakpointQuery.small`
      width: 52px;
     left: -60px;
    `}
  }

  ${minBreakpointQuery.small`
    margin-bottom: 20px 0;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 30px 0;
  `}
    
  ${minBreakpointQuery.large`
    margin-bottom: 40px 0;
    ${fontSize(24)};
  `}
`;

const StyledText = styled.div``;

const StyledButton = styled(Button)`
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 250px;
  max-width: 286px;
  width: 100%;
  padding: 25px 0;
  border-radius: 100px;
  color: ${standardColours.black};
  background-color: ${standardColours.white};
  border: solid 1px ${standardColours.black};
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};
  transition: ${standardTransition('background-color')};
  display: block;

  &:hover {
    background-color: ${brandColours.primary};
    border-color: ${brandColours.senary};
    transition: ${standardTransition('background-color')};
  }
`;

const CareerSingle = ({
  image,
  items: {
    benefits,
    description,
    displaySalary,
    link,
    location,
    qualifications,
    title,
  },
  breadcrumbs,
}) => (
  <StyledCareerSingle>
    <StyledContainer wide={true}>
      <StyledBreadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} />
      <StyledCareersFlag image={careersFlag} alt={'Careers Flag'} />
      <StyledInner>
        <StyledIntro>
          {image && (
            <StyledImageWrapper>
              <StyledImage
                src={image.url}
                alt={image.title}
                title={image.title}
              />
            </StyledImageWrapper>
          )}

          <StyledInfo>
            <StyledHeading>{title}</StyledHeading>
            <StyledLocation>
              <b>Location:</b>
              {location}
            </StyledLocation>
            <StyledSalary>
              <b>Salary Details:</b>
              {displaySalary}
            </StyledSalary>
          </StyledInfo>
        </StyledIntro>

        <StyledContent>
          <StyledSubHeading>About The Role</StyledSubHeading>
          <StyledText dangerouslySetInnerHTML={{ __html: description }} />
          <StyledSubHeading>About You</StyledSubHeading>
          <StyledText dangerouslySetInnerHTML={{ __html: qualifications }} />
          <StyledSubHeading>About Us</StyledSubHeading>
          <StyledText dangerouslySetInnerHTML={{ __html: benefits }} />

          <StyledButton href={link} target="_blank" rel="noopener">
            Apply
          </StyledButton>
        </StyledContent>
      </StyledInner>
    </StyledContainer>
  </StyledCareerSingle>
);

export default CareerSingle;
