import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CareerSingle from '../components/CareerSingle';

const CareerSingleTemplate = ({
  data: {
    contentfulCareers: { title, slug },
    careersPost,
    contentfulCompany,
  },
}) => (
  <Layout>
    <main>
      <CareerSingle
        image={contentfulCompany ? contentfulCompany.image : undefined}
        items={careersPost}
        breadcrumbs={[{ text: title, slug: slug }]}
      />
    </main>
  </Layout>
);

export const CareerSingleTemplateeQuery = graphql`
  query CareerSingleTemplateQuery($id: String!, $companyId: String!) {
    contentfulCareers {
      title
      slug
    }
    careersPost(id: { eq: $id }) {
      benefits
      company
      companyID
      dateCreated
      datePosted
      description
      displaySalary
      id
      industry
      industryID
      link
      location
      locationID
      positionID
      qualifications
      title
      vacancyID
      vacancyType
      vacancyTypeID
    }
    contentfulCompany(companyId: { eq: $companyId }) {
      image {
        url
        title
      }
      companyId
    }
  }
`;

export default CareerSingleTemplate;

export const Head = ({
  data: {
    careersPost: { title },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={`Careers Single Page For ${title}`} />
    </>
  );
};
