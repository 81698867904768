import React from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  minBreakpointQuery,
  zIndexLayers,
} from '../styles';
import { Link } from './ui';

const StyledBreadcrumbs = styled.div`
  z-index: ${zIndexLayers.first};
  position: absolute;
  top: 26px;
  left: 0;

  ${minBreakpointQuery.xlarge`
    margin-left: 54px;
  `}
`;

const StyledList = styled.ol``;

const StyledItem = styled.li`
  display: inline;
  ${fontSize(16)};
  line-height: 1.7;

  &:after {
    content: '>';
    margin: 0 10px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

const StyledItemText = styled.span`
  display: inline;
  ${fontSize(16)};
  line-height: 1.7;

  ${({ isCurrent }) => {
    if (isCurrent) {
      return css`
        font-family: ${brandFonts.quaternary};
      `;
    } else {
      return css`
        &:hover {
          color: ${brandColours.primary};
        }
      `;
    }
  }}
`;

const StyledLink = styled(Link)``;

const Breadcrumbs = ({ breadcrumbs, currentPageTitle, ...props }) => (
  <StyledBreadcrumbs {...props}>
    <StyledList>
      <StyledItem>
        <StyledItemText>
          <StyledLink to="/">Home</StyledLink>
        </StyledItemText>
      </StyledItem>
      {breadcrumbs &&
        breadcrumbs.map(({ slug, text }, i) => (
          <StyledItem id={`breadcrumb-${i}`} key={i}>
            <StyledItemText>
              <StyledLink to={slug}>{text}</StyledLink>
            </StyledItemText>
          </StyledItem>
        ))}
      <StyledItem>
        <StyledItemText isCurrent={true}>{currentPageTitle}</StyledItemText>
      </StyledItem>
    </StyledList>
  </StyledBreadcrumbs>
);

export default Breadcrumbs;
